// extracted by mini-css-extract-plugin
export var closeButton = "video-module--closeButton--7e01d";
export var container = "video-module--container--9a69d";
export var customlink = "video-module--customlink--b5c23";
export var field = "video-module--field--1e955";
export var mainlayer = "video-module--mainlayer--a0d52";
export var modal = "video-module--modal--b67cc";
export var overlay = "video-module--overlay--5a2fe";
export var policylink = "video-module--policylink--ac216";
export var sendbutton = "video-module--sendbutton--84dad";
export var video = "video-module--video--6588e";