import * as React from 'react'
import * as Styles from './portfolioitem.module.sass'
import { useInView } from 'react-intersection-observer'
import ReactVideo from './reactvideo'
import useWindowSize from '../utils/useWindowSize'

const PortfolioItem = ({ onClick, title, title2, tag, shortUrl, poster }) => {
  const { width , ar} = useWindowSize();
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: (ar<1) ? 0.5*ar : 0.5,
  });
  const [hover, setHover] = React.useState(false);

  return (
    <div ref={ref} onClick={onClick} onMouseEnter={() => { setHover(true) }} onMouseLeave={() => { setHover(false) }} className={`react-player-wrapper ${Styles.videowrap}`}>
      <div className={`${Styles.link}`}>
        <div className={`${Styles.center}`}>
          <h2>{title} | <strong>{title2}</strong></h2>
          <span>#{tag}</span>
        </div>
      </div>
      <ReactVideo
        width="100%"
        height="100%"
        playing={(width < 1024) ? inView : hover}
        playsinline
        loop={true}
        muted={true}
        video={shortUrl}
        poster={poster}
        className={`${Styles.video}`}
        alt={`Must Be Family шоурил`} />
    </div>
  )
}

export default PortfolioItem
