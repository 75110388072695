import * as React from 'react'
import * as Styles from './video.module.sass'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import PortfolioItem from '../components/portfolioitem'
import ReactVideo from '../components/reactvideo'
import Seo from '../components/seo'
import useWindowSize from '../utils/useWindowSize'
import { Formik, Form, Field, ErrorMessage } from 'formik'

const data = [{
  title: `Brilliant Style`,
  title2: `Dance`,
  tag: `Ювелирные бренды`,
  filename: `Bs_Dance`,
}, {
  title: `Лето`,
  title2: `Монгольфьер`,
  tag: `Ювелирные бренды`,
  filename: `Leto_Shar`,
}, {
  title: `Brilliant Style`,
  title2: `Her Impala`,
  tag: `Ювелирные бренды`,
  filename: `bs_leona_full_2`,
}, {
  title: `Brilliant Style`,
  title2: `Pulse of the city`,
  tag: `Ювелирные бренды`,
  filename: `Bs_Ostozenka`,
}, {
  title: `Гудтаймс`,
  title2: `Химки, Лето`,
  tag: `Музыка`,
  filename: `GT_Himki`,
}, {
  title: `Buriday`,
  title2: `Desepticon`,
  tag: `Музыка`,
  filename: `Buriday_Deseptikon`,
}, {
  title: `Modimio`,
  title2: `Путешественники`,
  tag: `реклама`,
  filename: `Modimio_travel`,
}, {
  title: `FREEDOM TAG`,
  title2: `АЛТАЙ`,
  tag: `реклама`,
  filename: `_web-mbf_freed_1`,
}, {
  title: `ALKOR`,
  title2: `PREMIUM`,
  tag: `Ювелирные бренды`,
  filename: `_web-mbf_alk`,
}, {
  title: `Zelenin World`,
  title2: `Designer's day`,
  tag: `Ювелирные бренды`,
  filename: `new_angle_final6_9`,
}, {
  title: `TOP DOG`,
  title2: `Join the cult`,
  tag: `события`,
  filename: `_web-mbf_topd_1`,
}, {
  title: `HSB`,
  title2: `Жизнь продолжается`,
  tag: `события`,
  filename: `hsb_30_ng`,
}, {
  title: `ДИЗАЙН ВЫХОДНЫЕ`,
  title2: `КОСТРОМА`,
  tag: `события`,
  filename: `_web-mbf_dv_1`,
}, {
  title: `BURIDAY`,
  title2: `ЛОЗА`,
  tag: `Музыка`,
  filename: `_web-mbf_loza_1`,
}, {
  title: `TUTTO BENE`,
  title2: `ПРЕВЕНТИВНАЯ МЕДИЦИНА`,
  tag: `реклама`,
  filename: `_web-mbf_ttbn_1`,
}, {
  title: `Молочный Гурман`,
  title2: `Сила вкуса`,
  tag: `Реклама`,
  filename: `milk`,
}, {
  title: `ЛИГА`,
  title2: `Вокруг света`,
  tag: `события`,
  filename: `liga_v03`,
}, {
  title: `TopDog`,
  title2: `Свобода не погибнет`,
  tag: `события`,
  filename: `topdogx_sound80`,
}, {
  title: `Lollipop Duo`,
  title2: `Promo`,
  tag: `Музыка`,
  filename: `dobro_promo`,
}, /* {
  title: `Tutto Bene`,
  title2: `Это твой день`,
  tag: `Реклама`,
  filename: `bene`,
}, {
  title: `Brilliant Style`,
  title2: `Girl's freedom`,
  tag: `Ювелирные бренды`,
  filename: `bs_words_30s`,
}, {
  title: `Smart Heart`,
  title2: `Raketa Fitness`,
  tag: `события`,
  filename: `raketa-rd`,
}, {
  title: `Tutto Bene`,
  title2: `Histoires de Parfums`,
  tag: `события`,
  filename: `de_parfum_groza`,
}, {
  title: `Modimio`,
  title2: `Удивительные насекомые`,
  tag: `Реклама`,
  filename: `modimio_fin080`,
}, {
  title: `TopDog`,
  title2: `Death Dance`,
  tag: `Документальный`,
  filename: `topdog_full`,
} */, {
  title: `Junwex`,
  title2: `Петербург`,
  tag: `Ивенты`,
  filename: `jd_all_2min`,
}]
const VideoPage = () => {
  const dfltStatus = { status: `notsubmitted`, msg: `` }
  const { width } = useWindowSize();
  const stringToHash = (string) => {
    let hash = 0;
    if (string.length === 0) return hash;
    for (let i = 0; i < string.length; i++) {
      let char = string.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash;
    }
    return hash;
  }
  const [open, setOpen] = React.useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [file, setFile] = React.useState(``)
  return (
    <>
      <Seo
        title={`Портфолио`}
        description={`Смотрите наши последние работы: ролики для ювелирных брендов, съёмка ивентов, рекламных видео, создание документальных видео.`}
        image={{ src: `/images/social_preview.png`, width: 1200, height: 628 }}
      />
      <div className={`is-relative`}> {
        data.map((item, index) => {
          return (
            <PortfolioItem
              key={stringToHash(JSON.stringify(item) + index)}
              onClick={() => { onOpenModal(); setFile((width < 1023) ? `mob/${item.filename}` : `${item.filename}`); }}
              title={item.title}
              title2={item.title2}
              poster={`https://cdn.mustbefamily.com/poster/${item.filename}.jpg`}
              tag={item.tag}
              shortUrl={`https://cdn.mustbefamily.com/short/${item.filename}.mp4`}
            />
          )
        })
      }
      </div>
      <div className={`${Styles.mainlayer}`}>&nbsp;</div>
      <div className={`${Styles.mainlayer}`}>
        <Formik
          initialStatus={dfltStatus}
          initialValues={{ phone: '' }}
          validate={values => {
            const errors = {};
            if (!values.phone) {
              errors.phone = 'Поле обязательно для заполнения';
            } else if (
              !/^[\d]{10,15}$/i.test(values.phone.replace(/[^\d]/g, ''))
            ) {
              errors.phone = 'Неизвестный формат номера, необходимо как минимум 10 цифр';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting, setStatus }) => {
            setStatus(dfltStatus)
            let formData = new FormData()
            formData.append(`phone`, values.phone.replace(/[^\d]/g, ''))
            fetch("/form.php", {
              method: "POST",
              body: formData
            }).then((response) => {
              if (response.ok) {
                return response.json()
              }
              return Promise.reject({ status: `error`, msg: `Упс! Что-то пошло не так. Попробуйте обновить страницу и попробовать ещё раз. Или воспользуйтесь кнопкой выше и позвоните нам!` })
            })
              .then(response => {
                setSubmitting(false);
                setStatus(response);
              })
              .catch((obj) => {
                setSubmitting(false);
                setStatus(obj);
              });
          }}
        >
          {({ isSubmitting, status }) => ((status && status.status === `ok`) ?
            <div className={``}>{status.msg}</div> : <>
              <h1>Хотите заказать видео?</h1>
              <h2>Оставьте номер и мы перезвоним.</h2>
              <Form>
                <div>
                  <Field placeholder={`+7 999 999 9999`} className={`${Styles.field}`} type="phone" name="phone" />
                  <ErrorMessage name="phone" component="div" />
                </div>
                <div>
                  <button className={`button is-outlined is-radiusless ${Styles.sendbutton} ${isSubmitting ? `is-loading` : ``}`} type="submit">
                    Отправить
                  </button>
                </div>
                {(status.status === `error`) ? <div className={``}>{status.msg}</div> : <></>}
                <div className={`${Styles.policylink}`}>
                  Нажимая кнопку отправить вы соглашаетесь с <a href={`/privacy-policy`} target={`_blank`} className={`${Styles.customlink}`}>политикой конфиденциальности</a>.
                </div>
              </Form>
            </>)}
        </Formik>

      </div>

      <Modal
        open={open}
        onClose={onCloseModal}
        center
        closeOnOverlayClick={false}
        classNames={{
          overlay: `${Styles.overlay}`,
          modalContainer: `${Styles.container}`,
          modal: `${Styles.modal}`,
          closeButton: `${Styles.closeButton}`
        }}>
        <div className={`react-player-wrapper`}>
          <ReactVideo
            width={`100%`}
            height={`100%`}
            controls={true}
            playsinline
            video={`https://cdn.mustbefamily.com/full8mb/${file}.mp4`}
            poster={`https://cdn.mustbefamily.com/poster/${file}.jpg`}
            className={`${Styles.video}`}
            alt={`Must Be Family шоурил`} />
        </div>
      </Modal>
    </>
  )
}



export default VideoPage
